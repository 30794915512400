.editabletext {
    display: block;
    border: solid 0.1rem transparent;
    border-radius: 0.8rem;
    padding: 0.6rem 0.8rem;
    position: relative;
    bottom: 0.7rem;
    min-height: 3rem;
    cursor: default;
}

.editabletext_enabled:hover {
    border-color: var(--color-galaxy-300);
    background-color: var(--color-galaxy-50);
    cursor: pointer;
}

.editabletext-button {
    display: inline-flex;
    border: 0.1rem solid var(--color-galaxy-300);
}

.editabletext-button:hover {
    background-color: var(--color-neptune-100);
}
