:root {
    --dimension-navbar-height: 4.8rem;
    --dimension-action-bar-height: 6.4rem;
    --dimension-drawer-header-min-height: 5.6rem;
    --dimension-drawer-header-multi-height: 27.8rem;
    --dimension-drawer-sort-min-height: 7.5rem;
    --dimension-drawer-filter-min-height: 7.5rem;
    --dimension-drawer-body-min-height: 7.5rem;
    --dimension-drawer-footer-min-height: 5.2rem;
    --dimension-drawer-width: 30rem;
    --dimension-closed-drawer-width: 4.8rem;
    --dimension-bar-offset-height: calc(
        var(--dimension-navbar-height) + var(--dimension-action-bar-height)
    );
    --dimension-task-action-bar-height: 5.7rem;
    --dimension-table-toolbar-height: 6.2rem;
    --dimension-round: 9000rem;
}
