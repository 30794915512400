$modal-width: 66rem;
$modal-height: 42rem;

.overlay {
    display: flex;
    justify-content: center;
    background-color: rgba(9, 16, 26, 0.2);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: var(--z-index-modal);
}

.modal {
    background-color: var(--color-comet);
    border-radius: var(--border-radius-l);
    box-shadow: 0 1.5rem 3.5rem 0 var(--color-box-shadow);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $modal-width;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    border-bottom: 0.1rem solid var(--color-galaxy-300);
    padding: 0 2.4rem;
    font-size: 1.8rem;
    font-weight: 500;
}

.modal-content-wrapper {
    height: 100%;
}

.modal_date {
    background-color: var(--color-galaxy-50);
    min-width: 76rem;
    min-height: 45rem;
    height: 50%;
}

.modal_small {
    min-width: $modal-width;
    min-height: $modal-height;
}

.modal_large {
    min-width: $modal-width;
    min-height: $modal-height;
    width: 80vw;
    height: 80vh;
}

.modal_dialogue {
    min-width: $modal-width;
    height: 37.5rem;
}

.modal_dialogue-content {
    row-gap: 1rem;
    height: 16rem;
    overflow-y: auto;
}
