@import 'src/ui/mixins/alert';
.alert {
    font-size: 1.2rem;
    line-height: 1.6rem;
    border: 0.1rem solid transparent;
    border-radius: var(--border-radius-l);
    padding: 0.8rem 1.2rem;
    column-gap: 0.4rem;
    @include alert-variant;

    // prevent icon component from shrinking
    .icon {
        flex-shrink: 0;
    }

    & + & {
        margin-top: 0.8rem;
    }
}

.alert_has-icon {
    padding-left: 0.8rem;
}

.alert_warning {
    @include alert-variant(
        $alert-background-color: var(--color-venus-100),
        $alert-color: var(--color-venus)
    );
}

.alert_warning-dark {
    @include alert-variant($alert-background-color: var(--color-venus-100));
}

.alert_danger {
    @include alert-variant(
        $alert-background-color: var(--color-mars-100),
        $alert-color: var(--color-mars)
    );
}

.alert_danger-dark {
    @include alert-variant($alert-background-color: var(--color-mars-100));
}

.alert_info {
    @include alert-variant(
            $alert-background-color: var(--color-neptune-100),
    )
}
