.icon-button {
    border: 0.1rem solid transparent;
    background-color: transparent;
    margin: 0;
    padding: 0;
    color: transparent;
    border-radius: var(--border-radius-l);

    // `flex` property allows the flexing element to enforce the strict width and height
    flex: none;
    width: 3.2rem;
    height: 3.2rem;

    span {
        font-size: var(--text-size-xxs);
        line-height: 1.6rem;
    }
}

.icon-button_clear {
    background-color: transparent;
}

.icon-button_text-over-icon {
    position: relative;

    .icon-button_text {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}
