@import 'src/ui/mixins/color-modifier';

.taskhistorystatus {}

.taskhistorystatus-line {
  border-left: 0.1rem solid;
  height: 3.6rem;
  @include color-modifier('color');
}

.taskhistorystatus-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  @include color-modifier('background-color');
}




