.routecard-metric {
    column-gap: 0.2rem;
    line-height: 1.4rem;
    font-size: var(--text-size-xs);
    color: var(--color-galaxy-500);

    .icon + .icon {
        margin-left: -0.2rem;
    }
}
