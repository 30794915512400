/* CSS for react-tabs */
.react-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: var(--dimension-drawer-body-min-height);
    flex-grow: 1;
}

.react-tabs__tab-list {
    border-bottom: 0.1rem solid var(--color-galaxy-300);
    margin: 0;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    list-style: none;
    padding: 1rem 2rem 0.6rem;
    cursor: pointer;
    color: var(--color-galaxy-500);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
}

.react-tabs__tab:hover {
    color: var(--color-ocean);
}

.react-tabs__tab--selected {
    border-bottom: 0.4rem solid var(--color-ocean);
    color: var(--color-galaxy-800);
}

.react-tabs__tab--disabled {
    cursor: default;
}
