@mixin button-variant(
    $button-color: var(--color-comet),
    $button-font-size: var(--text-size-s),
    $button-line-height: 2rem,
    $button-background-color: var(--color-ocean),
    $button-border: 0.1rem solid var(--color-ocean),
    $button-border-radius: var(--border-radius-l),
    $button-padding: 1.2rem 5rem,
    $button-hover-color: $button-color,
    $button-hover-background-color: var(--color-ocean-500),
    $button-hover-border-color: var(--color-ocean-500),
    $button-disabled-color: var(--color-galaxy-400),
    $button-disabled-background-color: var(--color-galaxy-200),
    $button-disabled-border-color: var(--color-galaxy-200)
) {
    color: $button-color;
    font-size: $button-font-size;
    line-height: $button-line-height;
    background-color: $button-background-color;
    border: $button-border;
    border-radius: $button-border-radius;
    padding: $button-padding;

    &:hover {
        color: $button-hover-color;
        background-color: $button-hover-background-color;
        border-color: $button-hover-border-color;
    }

    &:disabled {
        &,
        &:hover {
            color: $button-disabled-color;
            background-color: $button-disabled-background-color;
            border-color: $button-disabled-border-color;
        }
    }
}

@mixin button-variant-primary() {
    @include button-variant;
}

@mixin button-variant-secondary() {
    @include button-variant(
        $button-color: var(--color-meteor),
        $button-background-color: var(--color-comet),
        $button-border: 0.1rem solid var(--color-galaxy-300),
        $button-hover-background-color: var(--color-neptune-100),
        $button-hover-border-color: var(--color-ocean)
    );
}

@mixin button-variant-link() {
    @include button-variant(
        $button-color: var(--color-meteor),
        $button-background-color: transparent,
        $button-border: 0.1rem solid transparent,
        $button-hover-background-color: transparent,
        $button-hover-border-color: transparent,
        $button-disabled-background-color: transparent,
        $button-disabled-border-color: transparent
    );

    &:hover {
        text-decoration: underline;
    }

    &:disabled {
        &,
        &:hover {
            text-decoration: none;
        }
    }
}
