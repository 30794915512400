.timeinput {
  margin: 0;
  color: var(--color-galaxy-800);
  border: solid 0.1rem var(--color-galaxy-300);
  border-radius: 0.8rem;
  font-size: 1.6rem;

  &:focus {
    outline: none;
    border-color: var(--color-ocean);
    background-color: var(--color-neptune-100);
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  &:disabled {
    color: var(--color-galaxy-400);
    background-color: transparent;
  }
}

.timeinput_table {
  padding: 0.6rem 0.8rem 0.6rem 1.6rem;
  width: 16rem;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    z-index: var(--z-index-action);
  }
}

.timeinput_form {
  width: 100%;
  padding: 1.2rem 1.6rem;
}

.timeinput_error {
  border-color: var(--color-mars-300);

  &:focus {
    border-color: var(--color-mars-300);
    background-color: var(--color-mars-100);
  }
}

.timeinput-icon {
  position: relative;
  left: -2.9rem;
}
