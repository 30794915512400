._header-1 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-xxl);
    font-weight: 500;
    line-height: 1.25; // 4.5rem;
}
._header-2 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-l);
    font-weight: 500;
    line-height: 1.25; // 3rem;
}
._header-2-alt {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-l);
    font-weight: 500;
    line-height: 1.25; // 3rem;
}
._header-3 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-m);
    font-weight: 500;
    line-height: 1.5; // 2.4rem;
}
._header-4 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-s);
    font-weight: 500;
    line-height: 2rem;
}
._header-4-alt {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-s);
    font-weight: 500;
    line-height: 1.5; // 2.1rem;
    text-transform: capitalize;
}

._text-1 {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-m);
    line-height: 1.5; // 2.4rem;
}
._text-2 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-s);
    line-height: 2rem;
}
._text-2-alt {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-s);
    line-height: 1.5; // 21rem;
}
._text-3 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
}
._text-3-alt {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
}
._text-4 {
    color: var(--color-galaxy-800);
    font-size: var(--text-size-xs);
    line-height: 2rem;
}
._text-4-alt {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
    line-height: 2rem;
}
._caption-1 {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
    font-weight: 700;
    line-height: 2; // 24px
    text-transform: capitalize;
}
._caption-2 {
    color: var(--color-galaxy-500);
    font-size: var(--text-size-xxs);
    font-weight: 700;
    line-height: 2; // 2rem
    letter-spacing: 0.01rem;
    text-transform: capitalize;
}
