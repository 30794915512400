._m-0 { margin: 0; }
._m-2 { margin: 0.8rem; }

._mx-0 {
    margin-left: 0;
    margin-right: 0;
}
._my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

._my-2 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

// margin top
._mt-0 { margin-top: 0; }
._mt-1 { margin-top: 0.4rem; }
._mt-2 { margin-top: 0.8rem; }
._mt-4 { margin-top: 1.2rem; }
._mt-5 { margin-top: 2rem; }
._mt-6 { margin-top: 2.4rem; }
._mt-12 { margin-top: 4.8rem; }
._mt-20 { margin-top: 8.0rem; }

// margin right
._mr-0 { margin-right: 0; }
._mr-1 { margin-right: 1rem; }
._mr-6 { margin-right: 2.4rem; }

// margin bottom
._mb-0 { margin-bottom: 0; }
._mb-1 { margin-bottom: 0.4rem; }
._mb-3 { margin-bottom: 0.8rem; }
._mb-4 { margin-bottom: 1.2rem; }
._mb-6 { margin-bottom: 2.4rem; }

// margin left
._ml-0 { margin-left: 0; }
._ml-1 { margin-left: 0.2rem; }
._ml-2 { margin-left: 0.4rem; }
._ml-4 { margin-left: 1.2rem; }
._ml-auto { margin-left: auto; }
