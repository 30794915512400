.editaddress-content {
  font-size: var(--text-size-m);
  color: var(--color-galaxy-800);
  padding: 2.4rem;
}

.editaddress-inputs {
  grid-template-columns: repeat(6, 1fr);
  column-gap: 2.1rem;
  row-gap: 1.2rem;
  margin-bottom: 4rem;
}

.editaddress-input_address {
  grid-column-start: 1;
  grid-column-end: 7;
}

.editaddress-input_city {
  grid-column-start: 1;
  grid-column-end: 3;
}

.editaddress-input_province {
  grid-column-start: 3;
  grid-column-end: 5;
}

.editaddress-input_postalcode {
  grid-column-start: 5;
  grid-column-end: 7;
}

.editaddress-collapsible {
  grid-column-start: 1;
  grid-column-end: 7;

  .collapsible-toggle_button {
    justify-content: start;
    padding: 0 0;
    .collapsible-toggle_text {
      padding: 0 8px;
    }
  }

  .collapsible-panel {
    padding: 0 0;
    margin-top: 1.2rem;
  }
}

.editaddress-coords {
  row-gap: 1.2rem;
  column-gap: 2.1rem;
}

.editaddress-coords-input_longitude {
  grid-column-start: 1;
  grid-column-end: 4;
}

.editaddress-coords-input_latitude {
  grid-column-start: 4;
  grid-column-end: 7
}

.editaddress-coords_alert {
  grid-column-start: 1;
  grid-column-end: 7;

  .alert_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


