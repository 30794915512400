.zoom-column {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: var(--border-radius-l);
    box-shadow: 0 0.2rem 0.6rem 0 var(--color-box-shadow);
    position: relative;
}

.zoom-column-btn-wrapper {
    padding: 0.2rem;
}

.zoom-column-divider {
    border-top: 0.1rem solid var(--color-galaxy-100);
    margin: 0.2rem 1rem;
}

.zoom-column-in,
.zoom-column-out {
    background-color: white !important;
    padding: 0.8rem !important;
    z-index: var(--z-index-map-controls) !important;
    border-radius: var(--border-radius-l) !important;
}
