.actionbaritem {
    min-width: 10rem;
    padding: 0;
    background-color: transparent;
    border: none;
    column-gap: 0.4rem;

    &:hover {
        cursor: pointer;
    }
}

.actionbaritem_icon {
    min-width: 3.6rem;
    height: 3.6rem;
    border-radius: 0.8rem;
    background-color: var(--color-neptune-100);
}

.actionbaritem_text {
    height: 3.6rem;
    padding: 0.1rem;
    flex-shrink: 0;

    &:hover {
        background-color: var(--color-galaxy-50);
        border-radius: 0.8rem;
    }

    span {
        color: var(--color-galaxy-800);
    }
}

.actionbaritem_type-noicon {
    padding: 1rem;
    border: 0.1rem solid var(--color-galaxy-200);
    border-radius: 0.8rem;

    &:hover {
        background-color: var(--color-galaxy-50);
    }

    span {
        color: var(--color-meteor);
    }
}
