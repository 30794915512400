.taskdetaildrawer {
    position: fixed;
    right : 0;
    top: var(--dimension-bar-offset-height);
    height: calc(100vh - var(--dimension-bar-offset-height));
    box-shadow: 0 1.5rem 3.5rem var(--color-box-shadow);
    background-color: var(--color-neptune-100);
    z-index: var(--z-index-drawers);
    overflow: auto;

    &.drawer { width: 36rem; }
}

.taskdetaildrawer-mappage {
    &.taskdetaildrawer {
        position: absolute;
        top: 0;
    }
}

.taskdetaildrawer-header {
    background-color: var(--color-comet);
    padding: 1rem 1.6rem 0rem 1.6rem;
    min-height: 0;
}

.taskdetaildrawer-header_sticky {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-header);
    background-color: var(--color-comet);
}

.taskdetaildrawer-status {
    display: grid;
    width: 9rem;
    grid-template-columns: 2.5fr;
    column-gap: 0.5rem;
}

.taskdetaildrawer-map {
    height: 13.7rem;
    border-radius: var(--border-radius-l);
    overflow:hidden;
}
.taskdetaildrawer-item {
    background-color: var(--color-comet);
    margin-bottom: 1rem;
    padding: 0 1.6rem 1rem 1.6rem;

    & + & {
        padding-top: 1rem;
    }
}

.taskdetaildrawer-item-body {
    height: 0;
    transition: height 300ms, padding-top 300ms;
    overflow: hidden;
    margin-top: 0.5rem;
}

.taskdetaildrawer-item-body .detail-content {
    font-size: var(--text-size-s);
    width: 100%;
    word-break: break-all;
    text-align: justify;
}

.taskdetaildrawer-item-icon {
    min-width: var(--icon-s);
    min-height: var(--icon-s);
}

.taskdetaildrawer-item_open .taskdetaildrawer-item-body {
    height: fit-content;
    overflow: visible;
}

.taskdetaildrawer-item-button {
    background: none;
    width: 100%;
    text-align: start;
    border: solid 0.1rem transparent;
    border-radius: var(--border-radius-l);
    padding: 0.6rem 0.8rem;
    position: relative;
    bottom: 0.7rem;
    min-height: 3rem;
}

.taskdetaildrawer-item-button:hover {
    border-color: var(--color-galaxy-300);
    background-color: var(--color-galaxy-50);
    cursor: pointer;
}

.taskdetaildrawer-item-button_disabled:hover {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
}

.taskdetaildrawer-tabs {
    background-color: var(--color-comet);
    padding: 0 1.6rem 1rem 1.6rem;
}
