@import 'src/ui/mixins/button';

.tooltipbutton {
    &:hover {
        cursor: pointer;
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }

    &:focus {
        outline: none;
    }
}

.tooltipbutton_primary {
    @include button-variant-primary;
}

.tooltipbutton_secondary {
    @include button-variant-secondary;
}

.tooltipbutton_link {
    @include button-variant-link;
}
