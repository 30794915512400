._w-100 {
    width: 100%;
}

._w-50 {
    width: 50%;
}

._w-91 {
    width: 91.11%;
}
