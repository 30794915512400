.marker-popup {
    position: absolute;
    bottom: 5rem;
    left: -13rem;
    min-width: 24rem;
    padding: 1.2rem;
    background-color: var(--color-comet-alpha-70);
    border-radius: var(--border-radius-l);
    -webkit-backdrop-filter: blur(2.4rem);
    backdrop-filter: blur(2.4rem);
}

/*fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .marker-popup {
        background-color: rgb(248, 247, 245, 1);
    }
}

.marker-popup:hover {
    cursor: default;
}

.marker-popup-header {
    font-size: var(--text-size-s);
    color: var(--color-galaxy-800);
    border-radius: 0.4rem 0.4rem 0 0;
    text-transform: capitalize;
}

.marker-popup-body {
    border-radius: 0 0 0.4rem 0.4rem;
    margin: 1rem 0.2rem 0 0.2rem;
    row-gap: 0.8rem;
}

.marker-popup-body .detail {
    margin: 0;
    font-size: var(--text-size-xs);
    color: var(--color-galaxy-500);
}

.marker-popup-body div.icon_union {
    position: relative;
    left: 0.4rem;
    top: 0.4rem;
}

.marker-popup-body div.icon {
    flex-shrink: 0;
}

.marker-popup-body button {
    height: 1.5rem;
    font-size: var(--text-size-xs);
}
