$z-indexes: (
    'toast': 120,
    'tooltip': 110,
    'modal': 100,
    'navbar': 22,
    'action': 20,
    'map-side-bar': 18,
    'drawers': 15,
    'loading': 10,
    'map-controls-overlay': 6,
    'map-controls': 5,
    'map-controls-underlay': 4,
    'sticky-header': 3,
    'map': 0
);

:root {
    @each $name, $value in $z-indexes {
        --z-index-#{$name}: #{$value};
    }
}
