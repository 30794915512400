@function hexToRGBA($hex, $alpha: 1) {
    @return rgba(red($hex), green($hex), blue($hex), $alpha);
}

:root {
    --color-black: #000000;
    --color-white: #ffffff;

    // primary colors
    --color-milkyway: #1f2923;
    --color-ocean: #5ea2ff;
    --color-comet: #ffffff;
    --color-sky: #82b5ff;
    --color-mars: #ff5670;
    --color-earth: #41bfac;
    --color-venus: #ff824a;
    --color-saturn: #ffc775;
    --color-neptune: #316dbe;
    --color-aurora: #3fa1b8;
    --color-meteor: #3282ff;

    // neutral colors
    --color-galaxy-50: #f6f8fb;
    --color-galaxy-100: #f0f4f8;
    --color-galaxy-200: #d9e2ec;
    --color-galaxy-300: #bcccdc;
    --color-galaxy-400: #a6b6c5;
    --color-galaxy-500: #6e7882;
    --color-galaxy-600: #486581;
    --color-galaxy-700: #334e68;
    --color-galaxy-800: #09101a;

    // supporting colors
    --color-mars-100: #ffe5e9;
    --color-mars-200: #ffbdbd;
    --color-mars-300: #ff9b9b;
    --color-mars-400: #ff5670;
    --color-mars-500: #e12d39;
    --color-mars-600: #cf1124;
    --color-mars-700: #ab091e;
    --color-mars-800: #610316;

    --color-earth-100: #effcf6;
    --color-earth-200: #c6f7e2;
    --color-earth-300: #8eedc7;
    --color-earth-400: #41bfac;
    --color-earth-500: #27ab83;
    --color-earth-600: #317d7c;
    --color-earth-700: #0d645f;
    --color-earth-800: #014d40;

    --color-venus-100: #fff4ef;
    --color-venus-200: #ffdccd;
    --color-venus-300: #ffb290;
    --color-venus-400: #ff824a;
    --color-venus-500: #f35627;
    --color-venus-600: #de3a11;
    --color-venus-700: #ad1d07;
    --color-venus-800: #841003;

    --color-saturn-100: #fffbf5;
    --color-saturn-200: #ffeccf;
    --color-saturn-300: #fdd8a3;
    --color-saturn-400: #ffc775;
    --color-saturn-500: #ffb140;
    --color-saturn-800: #8c5e1e;

    --color-neptune-100: #f3f8ff;
    --color-neptune-200: #dfecff;
    --color-neptune-300: #4191ff;
    --color-neptune-400: #316dbe;
    --color-neptune-500: #295fa7;
    --color-neptune-800: #192949;

    --color-aurora-100: #f5fdff;
    --color-aurora-200: #bfe9f3;
    --color-aurora-300: #7cbfcf;
    --color-aurora-400: #3fa1b8;
    --color-aurora-500: #198ba6;

    --color-ocean-500: #478ae5;

    // colors with alpha
    --color-overlay: #{hexToRGBA(#09101a, 0.15)}; // galaxy-800, 20%
    --color-box-shadow: #{hexToRGBA(#182941, 0.15)}; // #182941, 15%
    --color-comet-alpha-40: #{hexToRGBA(#ffffff, 0.4)}; // comet, 40%
    --color-comet-alpha-70: #{hexToRGBA(#ffffff, 0.7)}; // comet, 70%
    --color-comet-alpha-90: #{hexToRGBA(#ffffff, 0.9)}; // comet, 90%

    // custom colors in components
    --color-depot-icon: #597592;
}
