.heading-1 {
  color: var(--color-galaxy-800);
  font-size: var(--text-size-m);
  font-weight: 500;
  line-height: 2.4rem;
}

.heading-2 {
  color: var(--color-galaxy-800);
  font-size: var(--text-size-s);
  font-weight: 500;
  line-height: 2rem;
}

.heading-3 {
  color: var(--color-galaxy-800);
  font-size: var(--text-size-s);
  font-weight: 400;
  line-height: 2rem;
}
