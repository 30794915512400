@import 'src/ui/mixins/scrollbar';
@import 'src/ui/mixins/stopscontrol-childmodal';

.resequencemodal-header {
    @include stopcontrol-childmodal-header;
}

.resequencemodal-content {
    @include stopcontrol-childmodal-content;
}

.resequencemodal-content-search {
    @include stopcontol-childmodal-content-search;
}

.resequencemodal-content-button_disclaimer {
    border-radius: var(--border-radius-l);
    color: var(--color-mars-400);
    background-color: var(--color-mars-100);
    padding: 0.8rem;
}
.resequencemodal-content-button {
    @include stopcontrol-childmodal-content-button;
}

.resequencemodal-stopitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;

    .stopitem-buttongroup {
        display: none;
    }

    &:hover {
        cursor: pointer;
        background-color: var(--color-neptune-100);
    }

    &:hover .stopitem-buttongroup {
        display: flex;
    }

    & + & {
        margin-top: 0.8rem;
    }

    .stopitem-button {
        width: auto;
        height: auto;
    }
}

.resequencemodal-stopitem-left {
    .stopitem-title {
        max-width: 13rem;
        overflow: hidden;
    }

    > button:first-of-type {
        background-color: var(--color-comet);
        border-radius: var(--dimension-round);
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.9rem;
    }
}

.resequencemodal-search {
    @include stopcontrol-childmodal-search;
}

.resequencemodal-search-dropwdown {
    @include stopcontrol-childmodal-search-dropwdown;
}

.resequencemodal-buttongroup {
    padding: 0 0.8rem;
    background-color: var(--color-comet);
    margin-top: 1.2rem;
    border-radius: var(--border-radius-l);
    display: flex;
    flex-direction: column;

    > button {
        display: grid;
        background-color: var(--color-comet);
        grid-template-columns: 2rem 1fr;
        grid-gap: 1rem;
        border: none;
        border-radius: 0;
        padding: 1.2rem 0;

        &:hover {
            background-color: var(--color-comet);
        }

        &:first-of-type {
            border-bottom: 0.1rem solid var(--color-galaxy-200);
        }

        > span {
            text-align: left;
            align-self: center;
        }
    }
}
