.stop-index_label {
    color: var(--color-galaxy-500);
}

$button-padding-x: 1.2rem;
$divider-height: 0.1rem;
$divider-position-left: $button-padding-x;
$divider-position-right: $button-padding-x;

.stopmodalmenubutton {
    height: 4rem;
    line-height: 1.6rem;
    background-color: var(--color-comet);
    padding: 0 $button-padding-x;

    // makes only the internal contents look disabled
    &[disabled] > * {
        opacity: 0.5;
    }

    // creates a divider between buttons
    // divider is indented left and right by the buttons `x` padding
    & + & {
        margin-top: $divider-height;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-color: var(--color-galaxy-200);
            height: $divider-height;
            top: -$divider-height;
            left: $divider-position-left;
            right: $divider-position-right;
        }
    }
}
